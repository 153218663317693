.pricing-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /*max-width: 1200px;*/
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-top: 150px;
    padding-bottom: 100px;


}

.pricing-plan {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    text-align: center;
    /*width: 15%;*/
    margin: 32px 20px 0 20px;
    min-width: 250px;
    max-width: 300px;
    padding: 20px;
}

.basic {
    background-color: #f2f2f2;
}

.premium {
    background-color: #fff;
}

.enterprise {
    background-color: #f2f2f2;
}

.price {
    font-size: 1.3rem;
    font-weight: normal;
    margin: 10px 0;
    font-family:  "Helvetica Neue", sans-serif;


}

.pricing-plan ul {
    list-style: none; /* Removes default bullet points */
    padding-left: 0; /* Removes default padding */
    text-align: left; /* Aligns items to the right */
}

.pricing-plan li {
    position: relative; /* Allows absolute positioning relative to each li */
    padding-left: 25px; /* Makes space for the checkmark */
}

.pricing-plan li::before {
    content: '✓'; /* Adds the checkmark */
    color: green; /* Sets the checkmark color */
    position: absolute; /* Positions the checkmark absolutely */
    left: 0; /* Positions the checkmark at the start of the li */
    top: 0; /* Aligns the checkmark with the top of the li */
}

button {
    background-color: #0072c6;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px;
    margin-top: 0px;
    cursor: pointer;
    align-self: center;

}
