.contactUsContainer {
    max-width: 600px;
    margin: auto;
    /*padding: 20px;*/
    font-family: "Helvetica Neue", sans-serif;
    padding:  70px 20px;
}
.googleMap{
    padding-top: 20px;
}
.contactForm{
    padding-top: 20px;
    padding-bottom: 30px;
}
.contactUsContainer h2{
    color: #5d5e67;
}

.contactMethods {
    margin-bottom: 20px;
}

.socialLinks a {
    margin-right: 10px;
}

.contactForm label {
    display: block;
    margin-bottom: 5px;
}

.contactForm input, .contactForm textarea {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
}

.contactForm button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.contactForm button:hover {
    background-color: #0056b3;
}
