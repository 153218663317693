.solutions .floating-image {
    flex: 1;
    background-image: url("./../assets/voicepro_communications_healthcare.png");
    height: 430px;
    min-width: 300px;

}

.solutions .floating-image2 {
    flex: 1;
    background-image: url("./../assets/voicepro_communications_dealerships.png");
    height: 491px;
    min-width: 300px;
}

.solutions .floating-image3 {
    flex: 1;
    background-image: url("./../assets/voicepro_communications_dial@2x.png");
    height: 450px;
    min-width: 300px;

}
