@media only screen and (max-width: 768px) {
  .site-nav {
    display: none;
  }

  .burger-menu {
    display: block;
    border: #f89d3c solid 0px;
  }

  .burger-menu i {
    font-size: 24px;
    color: #333;
    cursor: pointer;
  }

  .site-header.responsive .site-nav .login-button {
    display: block;
    text-align: center;
  }

  .site-header.responsive .menu li {
    display: block;
    margin: 0;
    padding: 0;
  }

  .site-header.responsive .menu li a {
    display: block;
    padding: 10px;
    border-bottom: 1px solid #f2f2f2;
  }
}

.site-header {
  margin: 0 auto;
  min-width: 800px;
  padding-top: 10px;
  transition: all 0.3s ease;
  height: 60px;
  max-width: 75rem;

}


.site-header.scroll {
  height: 30px;
  /*padding: 9px;*/
  transition: all 0.7s ease;
}

.site-header.scroll .login-button {
  padding: 5px 10px;
  font-size: 12px;
  margin-top: 5px;

  transition: all 0.3s ease;
}

.site-header.scroll .menu li a {
  font-size: 14px;
  padding: 10px;
  transition: all 0.3s ease;
}

.site-header.scroll .logo svg {
  height: 40px;
  transition: all 0.3s ease;
}

.site-nav {
  float: left;
}

.site-nav2 {
  float: left;
  padding-left: 120px;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #3a3c40;

}

.menu li {
  display: inline-block;
}

.menu li a {
  display: block;
  padding: 20px;
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.menu li a:hover {
  background-color: #f2f2f2;
}

.header-container {
  text-align: center;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-top: #f89d3c solid 3px;
}

.logo {
  float: left;
}

.header-buttons {
  float: right;
  /*margin-top: 12px;*/
  border-radius: 5px;
}

.login-button,
.buy-now-button {
  display: inline-block;
  padding: 12px 15px;
  /*border-radius: 5px;*/

  text-decoration: none;

  margin-top: 12px;

  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;

  font-size: 15px;
  text-align: left;
  color: #fff;

  background: #508bfa;
}

.getstarted-button {
  font-size: medium;
  top: 6px;
  position: relative;

}

.header-phone {
  display: inline-block;
  margin-right: 20px;
  font-size: 16px;
  color: #333;
}

.header-phone i {
  margin-right: 5px;
}

.section-container {
  display: flow;
  align-items: center;
  justify-content: center;
}

.section-one {
  padding-top: 77px;

  /*max-width: 800px;*/
  width: 100%;
  background: #edeceb;
  border: 1px solid #cbcbcb;
}
.section-footer {
  padding-top: 60px;
  padding-bottom: 100px;

  /*max-width: 800px;*/
  width: 100%;
  background: #808080;
  border: 1px solid #cbcbcb;
}
.section-group-footer {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  margin: 0 auto;
  max-width: 75rem;
}

.section-group {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  max-width: 75rem;
}

.grop2 {
  flex: 1;
  flex-direction: row-reverse;
  display: flex;
}

.group3 {
  flex: 1;
  flex-direction: column;
  display: flex;
}

.floating-image {
  flex: 1;
  background-image: url("assets/voice_phone.png");
  height: 491px;
  min-width: 300px;

}

.image2 {
  background-image: url("assets/voicepro_communications_solutions.png");
  width: 304px;
  height: 229px;
  max-width: 304px;
  float: left;
  margin: 100px 40px 50px 10px;
}

.section-footer-logos {
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: row;
  flex-flow: row wrap;
  display: flex;

}

.ailogify-logo {
  padding: 20px;
  flex: 1;
}

.sas-logo,
.pci-logo {
  padding: 20px;
}

.text2 {
  padding: 10px;
  display: flex;
  flex-direction: column;
  float: right;
  max-width: 650px !important;
}

.text3 {
  max-width: 1200px !important;

  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}


.text2 .buttons {
  text-align: right;
  order: -1;
  top: 60px;
  position: relative;
}

.floating-text {
  padding: 10px;
  color: #404040;
  max-width: 550px;
  min-width: 300px;
  margin: 0 auto;
  flex: 1;
}

h2 {
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: 30px;
  color: #404040;
}

.floating-text p {
  line-height: 1.5;
  font-family: "Helvetica Neue";
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #404040;
}

.footer-column{
  /*flex: 1;*/
  padding: 10px;

  width: 130px;
}

.footer-menu {
  /*flex: 1;*/
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  /*line-height: 16px;*/
  text-align: left;
  color: #e0e0e0;


}

.footer-menu li {
  /*display: inline-block;*/
}

.footer-menu li a {
  display: block;
  margin: 10px;
  text-decoration: none;
  color: #e0e0e0;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 300 ;
  font-size: 13px;
}

.footer-menu li a:hover {
  /*background-color: #f2f2f2;*/
}
.footer-column h4{
  font-family: "Helvetica Neue";
  font-weight: normal;
  font-size: 13px;
  text-align: left;
  color: #fff;

  padding-left: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.footer-copyright{
  padding-bottom: 22px;
  padding-right: 22px;
  display: flex;
  align-items: end;
  flex-direction: column;
  flex: 1;
  font-family: "Helvetica Neue", serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #e0e0e0;
  min-width: 300px;
  margin-top: auto;
}
.footer-logo{
  padding: 0px;
  margin-right: -24px;

}
