.terms {
    padding: 100px 20px 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
{
    padding: 100px 20px 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.terms h1, .terms h2 {
    text-align: center;
    color: #454549;
}

.terms h1 {
    margin-bottom: 0.5em;
    font-size: 1.5em;
    font-weight: bold;
}

.terms h2 {
    text-align: left; /* Aligns h2 headings to the left */
    margin-top: 2em;
    margin-bottom: 1em;
    color: #454549; /* A modern blue */
    font-size: 1.5em;
    font-weight: normal;

}

.terms p {
    text-align: justify;
    line-height: 1.6;
    color: #666;
}

@media (max-width: 768px) {
    .terms {
        padding: 100px 20px 20px;
    }

    .terms h1, .terms h2 {
        font-size: 1.2em;
    }
}
