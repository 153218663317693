.support-page{
    padding-top: 70px;
    text-align: left;
    max-width: 1000px;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
strong{
    font-weight: bold;
    color: #3a3c40;
}
.support-page h2 {
    text-align: left; /* Aligns h2 headings to the left */
    margin-top: 2em;
    margin-bottom: 1em;
    color: #454549; /* A modern blue */
    font-size: 1.5em;
    font-weight: bold;

}

.accordion {
    /*background-color: #eee;*/
    /*color: #444;*/
    cursor: pointer;
    padding: 20px 0px 20px;

    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    transition: 0.4s;
    font-size: 1.2em;
}

.accordion:hover, .accordion.open {
    /*background-color: #ccc;*/
}

.panel {
    padding: 0 18px;
    display: none; /* Initially hide all panels */
    background-color: white;
    overflow: hidden;
}

.panel.open {
    display: block; /* Show panel when its state is 'open' */
}
