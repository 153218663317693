.careersContainer {
    padding: 100px 20px 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.jobListings {
    list-style: none;
    padding: 0;
}

.jobListing {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
}

.jobListing h3 {
    margin-top: 0;
}

.jobListing p {
    margin-bottom: 10px;
}

.jobListing a {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
}

.jobListing a:hover {
    background-color: #0056b3;
}
